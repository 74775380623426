<template>
	<MhRouterView class="EventView view" :isContentReady="isContentReady">
		<div class="view__inner">
			<div class="minHeight100vhMinusMainHeader">

				<!--
				<MhPre :name="'post.acf'"
					:data="_.get( post, 'acf' )"
					:isStyled="true"
					:maxHeight="'750px'"
					:keyColumnWidth="'8em'"
				></MhPre>
				-->

				<EventDetail
					:image="_.get( post, 'acf.image' )"
					:title="_.get( post, 'title.rendered' )"
					:dateTime="getDateTimeStr( post )"
					:location="_.get( post, 'acf.location' )"
					:text="_.get( post, 'acf.text' )"
				></EventDetail>

				<Spacer></Spacer>
				<Spacer></Spacer>

			</div>

			<MainFooter
				:data="app.footerData"
			></MainFooter>
		</div>
	</MhRouterView>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v5/MhRouterView.vue'
	import EventDetail from '@/components/contentModules/EventDetail.vue'
	import Spacer from '@/components/contentModules/Spacer.vue'
	import MainFooter from '@/components/MainFooter.vue'

	export default {
		name: 'PeopleView',
		components: {
			MhRouterView,
			EventDetail,
			Spacer,
			MainFooter,
		},
		mixins: [ restHandler ],
		directives: {},
		props: {},
		data(){
			return {
				post: undefined,
				isContentReady: false,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			getDateTimeStr( post ){
				// 15.7.2023 • 17<sup>30</sup>–22<sup>30</sup> h

				const date = this._.get( post, 'acf.startDate', '' )
				const startTime = this._.get( post, 'acf.startTime', '' )
				const endTime = this._.get( post, 'acf.endTime', '' )

				const year = date.substring( 0, 4 )
				const month = date.substring( 4, 6 )
				const day = date.substring( 6, 8 )

				const startTimeHours = startTime.substring( 0, 2 )
				const startTimeMinutes = startTime.substring( 2, 4 )
				const endTimeHours = startTime.substring( 0, 2 )
				const endTimeMinutes = startTime.substring( 0, 2 )

				let str = day + '.' + month + '.' + year +
					' &nbsp; ' + startTimeHours + '<sup>' + startTimeMinutes + '</sup>'

				if( endTime ){
					str += '–' + endTimeHours + '<sup>' + endTimeMinutes + '</sup>'
				}
				if( startTimeHours ){
					str += '&nbsp;h'
				}

				return str
			},
			fetchAll( finalCallback ){
				let thingsToLoad = 1

				const runCallback = ()=>{
					--thingsToLoad

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				// for post-draft previews we must use the postId to fetch the draft
				const postId = this.$route.query.postId
				const postSlug = this.$route.params.slug
				const fetchParams = postId ? { postIds : postId, preview : true } :	{ slug : postSlug }

				// fetch post
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : fetchParams,
					callback : (response) => {
						this.post = response.data.result[0]

						runCallback()
					},
				})
			},
		},
		mounted() {
			this.fetchAll(()=>{
				this.isContentReady = true

				EventBus.$emit('registerTranslation', this._.get( this.post, 'polylang.translations' ) )
			})
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.PeopleView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
